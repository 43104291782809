import React from 'react';
import { last } from 'ramda';
import { Box, Container, Flex, Hide } from '@qga/roo-ui/components';
import { useRouter } from 'next/router';
import Link from '@/components/Link';
import Item from './components/Item';
import usePageHeader from './hooks/usePageHeader';
import { useAppSettings } from '../AppSettingsProvider';
import NavItem from './components/NavItem';
import { useNavigation } from '@/v2/contexts/navigation';
import { dataLayer } from '@/v2/utils/dataLayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';

const PageHeader = () => {
  const shouldUseNewEvents = useGA4Events();
  const router = useRouter();
  const { visible } = usePageHeader();

  // need to determine if legacy/v2 context
  const app = useAppSettings();
  const nav = useNavigation();
  const pageHeader = app.pageHeader.length > 0 ? app.pageHeader : nav;

  if (!visible) {
    return null;
  }

  const onClick = (text, url, index) => {
    if (shouldUseNewEvents) {
      dataLayer.menuEvent({ text, url, index });
    }
  };

  return (
    <Hide xs sm>
      <Box boxShadow="light">
        <Container>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              {pageHeader &&
                pageHeader.map((navigation, index) => (
                  <React.Fragment key={navigation.key}>
                    {navigation.isLink ? (
                      <Link.Text
                        p="4"
                        href={navigation.url}
                        onClick={() =>
                          onClick(navigation.text, navigation.url, index)
                        }
                      >
                        <NavItem
                          active={
                            last(navigation?.url?.split('/')) ===
                            last(router?.asPath?.split('/') || [])
                          }
                        >
                          {navigation.text}
                        </NavItem>
                      </Link.Text>
                    ) : (
                      <Item data={navigation.groups} onClick={onClick}>
                        {navigation.text}
                      </Item>
                    )}
                  </React.Fragment>
                ))}
            </Box>

            <Link.Text p="4" href="/contact-us">
              <NavItem
                onClick={() =>
                  onClick('Contact us', '/contact-us', pageHeader.length)
                }
                active={router.pathname === '/contact-us'}
              >
                Contact us
              </NavItem>
            </Link.Text>
          </Flex>
        </Container>
      </Box>
    </Hide>
  );
};

export default PageHeader;
