import React from 'react';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import Text from '@/components/Text';

const StyledNavItem = styled(Text)`
  font-family: ${themeGet('headerFontFamily')};

  ${(props) =>
    props.active &&
    css`
      border-bottom-color: ${themeGet('colors.primary')(props)};
      border-bottom-style: solid;
      border-bottom-width: 2px;
    `}
`;

const NavItem = ({ children, ...props }) => (
  <StyledNavItem
    bold
    color="text.heading"
    fontSize={['sm', null, 'base']}
    lineHeight="normal"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </StyledNavItem>
);

NavItem.propTypes = {
  children: PropTypes.string.isRequired,
};

export default NavItem;
