import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Icon, NakedButton } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import Dropdown from '@experiences-ui/shared/components/Dropdown';
import ClickOutside from '@/components/ClickOutside';
import Text from '@/components/Text';
import Link from '@/components/Link';
import NavItem from '../NavItem';

const StyledText = styled(Text)`
  &:hover {
    color: ${themeGet('colors.text.hover')};
  }
`;

const Item = ({ children, data, onClick }) => {
  const flatLinks = data
    .flatMap(({ links }) => links)
    .flatMap(({ items }) => items);

  return (
    <Dropdown
      toggle={(show, ref, { isShown, hide }) => (
        <NakedButton
          ref={ref}
          data-testid="TOGGLE"
          onMouseEnter={show}
          onMouseLeave={hide}
          onClick={show}
        >
          <Flex
            data-testid="TOGGLER"
            p="4"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(isShown && {
              bg: 'white',
              mt: '-1px',
              borderTop: '1',
              borderColor: 'greys.smoke',
            })}
          >
            <NavItem pr="2">{children}</NavItem>
            <Icon
              data-testid="TOGGLER_ICON"
              name="expandMore"
              color="brand.primary"
            />
          </Flex>
        </NakedButton>
      )}
      content={(hide, { show }) => (
        <ClickOutside onOutsideClick={hide}>
          <Flex
            onMouseEnter={show}
            onMouseLeave={hide}
            data-testid="CONTENT"
            bg="white"
            boxShadow="0px 10px 15px rgba(0,0,0,0.1)"
            py="4"
          >
            {data.map((column, i) => (
              <React.Fragment key={column.key}>
                <Box width="260px" px="8">
                  {column.links.map(({ region, items, key }) => (
                    <Flex key={key} flexDirection="column">
                      {region && (
                        <Box
                          py="2"
                          borderBottom="1px solid"
                          borderColor="border"
                        >
                          <Text bold>{region}</Text>
                        </Box>
                      )}
                      {items.map(({ key: itemKey, url, text }) => (
                        <Box
                          key={itemKey}
                          my="2"
                          onClick={() => {
                            onClick(
                              text,
                              url,
                              flatLinks.findIndex((item) => item?.url === url),
                            );
                            hide();
                          }}
                        >
                          <Link.Naked href={url}>
                            <StyledText>{text}</StyledText>
                          </Link.Naked>
                        </Box>
                      ))}
                    </Flex>
                  ))}
                </Box>
                {i !== data.length - 1 && (
                  <Box data-testid="DIVIDER" width="1px" bg="greys.smoke" />
                )}
              </React.Fragment>
            ))}
          </Flex>
        </ClickOutside>
      )}
    />
  );
};

Item.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default Item;
