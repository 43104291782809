import type { UI } from '@/v2/types';
import type { ReactNode } from 'react';
import { Flex } from '@qga/roo-ui/components';

import { AppProvider } from '@/v2/contexts/app';
import { NavigationProvider } from '@/v2/contexts/navigation';

// Legacy components
import SiteHeader from '../../../../components/Header';
import SiteFooter from '../../../../components/SiteFooter';
import SiteNav from '../../../../components/PageHeader';
import SiteMessage from '@/v2/components/ui/SiteMessage';

export interface Props {
  children: ReactNode;
  importantInformation?: Array<UI.ImportantInformation> | null;
  navigation: Array<UI.Navigation>;
  informationMessage: UI.Message | null;
  warningMessage: UI.Message | null;
  marketingMessage: UI.MarketingMessage | null;
}

const Layout = ({
  children,
  importantInformation = null,
  navigation,
  warningMessage,
  informationMessage,
  marketingMessage,
}: Props) => {
  return (
    <AppProvider value={{ importantInformation }}>
      <NavigationProvider value={navigation}>
        <Flex flexDirection="column" bg="background.page">
          <SiteMessage siteMessage={warningMessage} variant="warning" />
          <SiteMessage siteMessage={informationMessage} variant="info" />
          <SiteHeader />
          <SiteNav />
          <SiteMessage siteMessage={marketingMessage} variant="marketing" />
          <main>{children}</main>
          <SiteFooter />
        </Flex>
      </NavigationProvider>
    </AppProvider>
  );
};

export default Layout;
