import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Icon } from '@qga/roo-ui/components';

const Item = ({ index, children, icon }) => (
  <Flex
    data-testid="INDEX_WRAPPER"
    position="relative"
    borderColor="greys.smoke"
    pr="3"
    bg="background.card"
    borderTop={['1', '1', '0'][index]}
    py={['4', '4', '3'][index]}
    pl={['3', '6', '9'][index]}
  >
    <Box data-testid="ACTIVE_WRAPPER" mb="-2px">
      {children}
    </Box>
    {icon && (
      <Flex
        data-testid="ICON_WRAPPER"
        alignItems="center"
        position="absolute"
        top="0"
        right="0"
        bottom="0"
        pr="3"
      >
        <Icon name={icon} color="brand.primary" />
      </Flex>
    )}
  </Flex>
);

Item.defaultProps = {
  icon: undefined,
};

Item.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
};

export default Item;
