import React from 'react';
import PropTypes from 'prop-types';
import { NakedButton, Flex, Icon } from '@qga/roo-ui/components';
import Text from '@/components/Text';
import NavBarItem from '../../../NavBarItem';

const Toggler = React.memo(({ onClick, close }) => (
  <NakedButton data-testid="TOGGLER" onClick={onClick}>
    <NavBarItem>
      <Flex
        pr={close ? '0' : '3'}
        width="60px"
        justifyContent="center"
        alignItems="center"
      >
        {close ? <Icon name="close" /> : <Text bold>Menu</Text>}
      </Flex>
    </NavBarItem>
  </NakedButton>
));

Toggler.defaultProps = {
  close: false,
};

Toggler.propTypes = {
  onClick: PropTypes.func.isRequired,
  close: PropTypes.bool,
};

export default Toggler;
