import React from 'react';
import { flatten } from 'ramda';
import { Flex } from '@qga/roo-ui/components';
import { NAVIGATION_LINKS } from '@/constants';
import Link from '@/components/Link';
import Text from '@/components/Text';
import { useAppSettings } from '@/components/AppSettingsProvider';
import Item from './components/Item';
import Pages from './components/Pages';
import useExpanded from './hooks/useExpanded';
import { useNavigation } from '@/v2/contexts/navigation';
import { dataLayer } from '@/v2/utils/dataLayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';

export const transformMobileSubNavigation = (data) =>
  data.map((d) => {
    if (d.isLink) {
      return d;
    }
    return {
      text: d.text,
      key: d.key,
      groups: flatten(
        d.groups.map((g) =>
          g.links.map((l) => ({
            region: null,
            items: l.items,
            key: l.key,
          })),
        ),
      ),
    };
  });

export const includeContactUs = (arr) => {
  arr.push({
    text: 'Contact us',
    url: '/contact-us',
    isLink: true,
    key: 'holidays-contact-us-mobile-key',
  });
  return arr;
};

const Navigation = React.memo(() => {
  const { expanded, toggleExpanded } = useExpanded();
  const shouldUseNewEvents = useGA4Events();

  // need to determine if legacy/v2 context
  const app = useAppSettings();
  const nav = useNavigation();
  const pageHeader = app.pageHeader.length > 0 ? app.pageHeader : nav;

  const updatedSubNavigation = includeContactUs(
    transformMobileSubNavigation(pageHeader),
  );

  const onClick = (text, url, index) => {
    if (shouldUseNewEvents) {
      dataLayer.menuEvent({ text, url, index });
    }
  };

  return (
    <Flex flexDirection="column" borderBottom="1" borderColor="border">
      {NAVIGATION_LINKS.map((link) => {
        const { href, target, key, children } = link;

        return (
          <React.Fragment key={key}>
            <Link.Naked
              target={target}
              href={href}
              onClick={() => onClick(key, href, 1)}
            >
              <Item index={0}>
                <Text bold>{children}</Text>
              </Item>
            </Link.Naked>
            {key === 'Holidays' &&
              updatedSubNavigation &&
              updatedSubNavigation.map((navigation) => (
                <React.Fragment key={navigation.key}>
                  {navigation.isLink ? (
                    <Link.Naked
                      href={navigation.url}
                      onClick={() =>
                        onClick(navigation.text, navigation.url, 2)
                      }
                    >
                      <Item index={1}>
                        <Text bold>{navigation.text}</Text>
                      </Item>
                    </Link.Naked>
                  ) : (
                    <Pages
                      expanded={expanded}
                      onClick={toggleExpanded}
                      data={navigation.groups}
                    >
                      {navigation.text}
                    </Pages>
                  )}
                </React.Fragment>
              ))}
          </React.Fragment>
        );
      })}
    </Flex>
  );
});

export default Navigation;
