import { HeightProps } from 'styled-system';
import styled from '@emotion/styled';
import { Image } from '@qga/roo-ui/components';
import withProps from '@/components/withProps';
import logoDark from './assets/logo-dark.svg';
import logoLight from './assets/logo-light.svg';

interface LogoProps {
  variant?: LogoVariants;
  height?: HeightProps['height'];
}

const Logo = styled(Image)`
  display: inline-flex;
`;

type LogoVariants = 'light' | 'dark';

export const LOGO_DESKTOP_HEIGHT = '32px';
export const LOGO_MOBILE_HEIGHT = '26px';

export default withProps(({ variant, height }: LogoProps) => ({
  src: variant === 'light' ? logoLight : logoDark,
  alt: 'Jetstar Holidays',
  height: height || [
    LOGO_MOBILE_HEIGHT,
    LOGO_MOBILE_HEIGHT,
    LOGO_DESKTOP_HEIGHT,
  ],
}))(Logo) as React.FC<LogoProps>;
