import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@qga/roo-ui/components';

const Wrapper = styled(Box)`
  & .global_features_navigation {
    z-index: 0;
  }

  @media print {
    display: none;
  }
`;

const SimplicityHeader = React.memo(({ mobile }) => {
  const className = mobile ? 'global_features_mobile' : '';

  return (
    <Wrapper
      className={className}
      data-widget-type="global_features_navigation"
    >
      <script
        type="qantas/widget"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: '{"environment": "production", "siteId": "holidays"}',
        }}
      />
    </Wrapper>
  );
});

SimplicityHeader.defaultProps = {
  mobile: false,
};

SimplicityHeader.propTypes = {
  mobile: PropTypes.bool,
};

export default SimplicityHeader;
