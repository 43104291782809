import { Flex } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import withProps from '@/components/withProps';

const NavBarItem = withProps({
  mx: ['3', null, '4'],
  alignItems: 'center',
  height: ['48px', null, '72px'],
})(styled(Flex)`
  &:last-of-type {
    margin-right: 0;
  }
  &:first-of-type {
    margin-left: 0;
  }
`);

export default NavBarItem;
