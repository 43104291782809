import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Drawer from './components/Drawer';
import Toggler from './components/Toggler';

const MobileMenu = () => {
  const [visible, setVisible] = useState(false);

  const router = useRouter();

  useEffect(() => {
    setVisible(false);
  }, [router]);

  return (
    <>
      <Toggler onClick={() => setVisible(true)} />
      <Drawer visible={visible} onClose={() => setVisible(false)} />
    </>
  );
};

export default MobileMenu;
