import React from 'react';
import { Box, Container, Flex } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

import Link from '@/components/Link';
import Logo, { LOGO_DESKTOP_HEIGHT } from '@/v2/components/ui/Logo';
import Text from '@/components/Text';
import { useAppSettings } from '@/components/AppSettingsProvider';
import { useNavigation } from '@/v2/contexts/navigation';
import BuildInfo from '@/libs/v2/components/BuildInfo';
import { BUILDKITE_INFO } from '@/config';

const GroupLink = styled(Box)`
  & + & {
    margin-top: ${themeGet('space.6')};
  }
`;

const StyledLinkNaked = styled(Link.Naked)`
  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

const Footer = styled(Box)`
  margin-bottom: -25px;
  background-color: #252b36;
`;

const SiteFooter = () => {
  // need to determine if legacy/v2 context
  const { pageHeader } = useAppSettings();
  const navigation = useNavigation();
  const groups = pageHeader[0] ? pageHeader[0].groups : navigation[0]?.groups;

  return (
    <Footer as="footer" color="text.inverse">
      <Container py="8">
        <Text
          as="h5"
          color="inherit"
          fontSize="base"
          fontWeight="700"
          lineHeight="normal"
          my="0"
        >
          Jetstar Holidays package destinations
        </Text>

        <Flex flexDirection={['column', null, 'row']} mt={[0, null, 6]}>
          {groups &&
            groups.map(({ key, links }) => (
              <Box key={key} flex={1} mt={[6, null, 0]}>
                {links.map(({ key: linkKey, region, items }) => (
                  <GroupLink key={linkKey}>
                    <Text
                      color="inherit"
                      fontSize="sm"
                      fontWeight="700"
                      display="block"
                      mb={2}
                    >
                      {region}
                    </Text>
                    {items.map(({ key: itemKey, text, url }) => (
                      <Box key={itemKey} mb={1}>
                        <StyledLinkNaked fontSize="sm" href={url}>
                          {text}
                        </StyledLinkNaked>
                      </Box>
                    ))}
                  </GroupLink>
                ))}
              </Box>
            ))}
        </Flex>

        <Flex
          flexDirection={['column', null, 'row']}
          justifyContent="space-between"
          alignItems={['flex-start', null, 'center']}
          fontSize="sm"
          mt="8"
          pt="8"
          borderTop="1px solid #fff"
        >
          <Flex
            flexWrap="wrap"
            flexDirection={['column', null, 'row']}
            alignItems={['flex-start', null, 'center']}
          >
            <Box mr="8">
              <Link.Text href="/">
                <Logo variant="light" height={LOGO_DESKTOP_HEIGHT} />
              </Link.Text>
            </Box>
            <Flex flexDirection={['column', null, 'row']} mt={[3, null, 0]}>
              <StyledLinkNaked href="/faq" mt={[0, null, 0]} mr="5">
                FAQ
              </StyledLinkNaked>
              <StyledLinkNaked href="/contact-us" mt={[4, null, 0]} mr="5">
                Contact us
              </StyledLinkNaked>
              <StyledLinkNaked
                external
                mt={[4, null, 0]}
                mr="5"
                href="https://www.jetstar.com/au/en/privacy-policy"
              >
                Privacy &amp; security
              </StyledLinkNaked>
              <StyledLinkNaked
                external
                mt={[4, null, 0]}
                href="https://www.jetstar.com/au/en/terms-and-conditions"
              >
                Terms of use
              </StyledLinkNaked>
            </Flex>
          </Flex>
        </Flex>
        <Box mt={[7, 7, 4]}>
          <Text color="inherit">
            We acknowledge the Aboriginal and Torres Strait Islander Traditional
            Custodians of the land on which we work, live and fly. We pay
            respect to Elders past, present and emerging.
          </Text>
        </Box>
        <Flex alignItems="center">
          <Box mt={6} textAlign={['left', null, 'right']}>
            <Text color="inherit">
              {`© ${new Date().getFullYear()} Jetstar Airways Pty Ltd. ABN: 33 069 720 243`}
            </Text>
          </Box>
        </Flex>
        <BuildInfo brand="jetstar" info={BUILDKITE_INFO} />
      </Container>
    </Footer>
  );
};

export default SiteFooter;
