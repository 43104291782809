import React from 'react';
import { Flex, Hide } from '@qga/roo-ui/components';
import { NAVIGATION_LINKS } from '@/constants';
import Logo from '@/v2/components/ui/Logo';
import NavBar from './components/NavBar';

const Navigation = () => (
  <>
    <Hide xs sm>
      <NavBar
        links={[
          {
            key: 'JetstarHolidaysLogo',
            href: '/',
            children: (
              <Flex>
                <Logo />
              </Flex>
            ),
          },
          ...NAVIGATION_LINKS,
        ]}
      />
    </Hide>
    <Hide md lg>
      <NavBar
        links={[
          {
            key: 'JetstarHolidaysLogo',
            href: '/',
            children: (
              <Flex>
                <Logo />
              </Flex>
            ),
          },
        ]}
      />
    </Hide>
  </>
);

export default Navigation;
