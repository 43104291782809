import PropTypes from 'prop-types';
import React from 'react';
import { NakedButton } from '@qga/roo-ui/components';
import Link from '@/components/Link';
import Text from '@/components/Text';
import Item from '../Item';
import { dataLayer } from '@/v2/utils/dataLayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';

const getExpandIcon = (expanded) => (expanded ? 'expandLess' : 'expandMore');

const Pages = React.memo(({ children, data, expanded, onClick }) => {
  const shouldUseNewEvents = useGA4Events();

  const isParentExpanded = expanded.startsWith(children);

  const flatLinks = data.flatMap(({ items }) => items);

  const onLinkClick = (text, url, index) => {
    if (shouldUseNewEvents) {
      dataLayer.menuEvent({ text, url, index });
    }
  };

  return (
    <>
      <NakedButton
        data-testid="PARENT_BUTTON"
        onClick={() => onClick(children)}
      >
        <Item index={1} icon={getExpandIcon(isParentExpanded)}>
          <Text bold>{children}</Text>
        </Item>
      </NakedButton>
      {isParentExpanded &&
        data.map(({ region, items, key }) => {
          const isChildExpanded = expanded.startsWith(
            region ? `${children}.${region}` : children,
          );
          return (
            <React.Fragment key={key}>
              {region && (
                <NakedButton
                  data-testid="CHILD_BUTTON"
                  onClick={() => onClick(`${children}.${region}`)}
                >
                  <Item index={1} icon={getExpandIcon(isChildExpanded)}>
                    {region}
                  </Item>
                </NakedButton>
              )}
              {isChildExpanded &&
                items.map(({ url, text, key: itemKey }) => (
                  <Link.Naked
                    data-testid="CHILD_LINK"
                    href={url}
                    key={itemKey}
                    onClick={() =>
                      onLinkClick(
                        text,
                        url,
                        flatLinks.findIndex((item) => item?.url === url),
                      )
                    }
                  >
                    <Item index={2}>
                      <Text>{text}</Text>
                    </Item>
                  </Link.Naked>
                ))}
            </React.Fragment>
          );
        })}
    </>
  );
});

Pages.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  expanded: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Pages;
