import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { borders, themeGet } from 'styled-system';
import Link from '@/components/Link';
import NavBarItem from '../../../NavBarItem';

const StyledLink = styled(Link.Text)`
  ${borders}

  font-family: 'Jetstar';
  width: 100%;
  transition: border 0.3s ease-in-out;
  text-align: left;

  :hover {
    border-bottom-color: ${themeGet('colors.primary')};
    color: ${themeGet('colors.text.heading')};
  }
`;

const StyledFlex = styled(NavBarItem)`
  &:first-of-type {
    flex-grow: 1;

    a {
      margin-bottom: 6px;
      border-bottom: none;
      :hover {
        border-bottom-color: transparent;
      }
    }
  }
`;

const NavBar = ({ links }) => (
  <Flex alignItems="center">
    {links.map(({ key, children, href, external, active }) => (
      <StyledFlex key={key}>
        <Box
          borderBottom="0px solid"
          borderColor={active ? 'ui.link' : 'transparent'}
          mb="-7px"
        >
          <StyledLink
            p={[null, null, '4']}
            borderBottom={['0', null, '8px solid']}
            borderColor={[null, null, active ? 'brand.primary' : 'transparent']}
            color="text.heading"
            fontSize={['sm', null, 'base']}
            href={href}
            external={external}
          >
            {children}
          </StyledLink>
        </Box>
      </StyledFlex>
    ))}
  </Flex>
);

NavBar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      href: PropTypes.string,
      children: PropTypes.node,
    }),
  ).isRequired,
};

export default NavBar;
