import React from 'react';
import { Box, Container, Flex, Hide } from '@qga/roo-ui/components';
import MobileMenu from './components/MobileMenu';
import Navigation from './components/Navigation';

const Header = () => (
  <Box bg="background.card" data-testid="HEADER" boxShadow="light" zIndex="2">
    <Container gutter="0">
      <Flex>
        <Box flex="1" ml="3">
          <Navigation />
        </Box>
        <Hide md lg as={Flex} ml="3">
          <MobileMenu />
        </Hide>
      </Flex>
    </Container>
  </Box>
);

export default Header;
